import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

export const DAYS_TO_SHOW = 7;
export const DATE_FORMAT = 'YYYY-MM-DD';
export const DOW_FORMAT = 'ddd';

dayjs.extend(utc);
dayjs.extend(timezone);

export const TZ = 'Australia/Sydney';

export function toDate(utc: number): string {
  return dayjs.utc(utc).tz(TZ).add(-3, 'hours').format(DATE_FORMAT);
}

export function strToDate(str: string): string {
  return dayjs(str).tz(TZ).add(-3, 'hours').format(DATE_FORMAT);
}
