import dayjs from 'dayjs';

import { Task } from '../../lib/datatype';
import { groupByToMap } from '../../lib/groupByToMap';
import { TasksTableHeader } from './TasksTableHeader';
import { strToDate, DAYS_TO_SHOW, DATE_FORMAT } from '../../lib/toDate';

function TodoStats({ todos, now }: { todos: Task[]; now: dayjs.Dayjs }) {
  const historyByDate = groupByToMap<Task, string>(todos, (t: Task) =>
    strToDate(t.dateCompleted)
  );

  return (
    <>
      {Array(DAYS_TO_SHOW)
        .fill(null)
        .map((_, index) => {
          const date = now
            .clone()
            .subtract(DAYS_TO_SHOW - 1 - index, 'days')
            .format(DATE_FORMAT);
          const count =
            historyByDate.get(date)?.reduce((acc) => 1 + acc, 0) || 0;
          const isEmpty = count === 0;

          return (
            <td className={`day${isEmpty ? ' empty' : ''}`} key={index}>
              {count > 0 && <div className="completed">+{count}</div>}
            </td>
          );
        })}
    </>
  );
}

export function TodosTable({ tasks }: { tasks: Task[] }) {
  const todos = tasks.filter((t) => t.type === 'todo' && !t.challenge?.broken);
  const now = dayjs();

  return (
    <table className="todos tasks-table">
      <TasksTableHeader section={'Todos'} now={now} />
      <tbody>
        <tr className="task">
          <TodoStats todos={todos} now={now} />
        </tr>
      </tbody>
    </table>
  );
}
