import './UserPage.scss';

import dayjs from 'dayjs';
import { useContext } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useSwipeable } from 'react-swipeable';

import { DataContext } from '../../contexts';
import UserCard from '../../habitica/UserCard';
import { isPerfectDay } from '../../lib/perfectDay';
import { DATE_FORMAT } from '../../lib/toDate';
import { DailiesTable } from './DailiesTable';
import { HabitsTable } from './HabitsTable';
import { TodosTable } from './TodosTable';

export function UserPage() {
  const data = useContext(DataContext);

  const today = dayjs().format(DATE_FORMAT);
  const { index } = useParams();
  const userIndex = parseInt(index || '', 10);
  const perfectToday = isPerfectDay(
    today,
    data.tasks[userIndex],
    data.magic.specialHabit
  );

  const navigate = useNavigate();
  const usersNum = data.users.length;
  const userSwitchHandler = useSwipeable({
    onSwipedLeft: () => {
      navigate('/' + ((userIndex + 1 + usersNum) % usersNum));
    },
    onSwipedRight: () => {
      navigate('/' + ((userIndex - 1 + usersNum) % usersNum));
    },
    touchEventOptions: {
      passive: false,
    },
  });

  return (
    <div id="user-page" {...userSwitchHandler}>
      <div className="header">
        <Link className="back" to="/">
          🔙
        </Link>
        <UserCard
          user={data.users[userIndex]}
          index={userIndex}
          perfectToday={perfectToday}
        />
      </div>
      <div className="table-habit">
        <HabitsTable userIndex={userIndex} tasks={data.tasks[userIndex]} />
      </div>
      <div className="table-daily">
        <DailiesTable userIndex={userIndex} tasks={data.tasks[userIndex]} />
      </div>
      <div className="table-todo">
        <TodosTable tasks={data.completedTasks[userIndex]} />
      </div>
    </div>
  );
}
