import './avatar.scss';

import { Fragment } from 'react';

import { User } from '../lib/datatype';

interface AvatarProps {
  member: User;
}

// https://github.com/HabitRPG/habitica/blob/c7aadede4dd29c3f7b7d6e35078804ddeb0be47b/website/client/src/components/avatar.vue
export default function Avatar({ member }: AvatarProps) {
  const visualBuffs = {
    snowball: `avatar_snowball_${member.stats.class}`,
    spookySparkles: 'ghost',
    shinySeed: `avatar_floral_${member.stats.class}`,
    seafoam: 'seafoam_star',
  };
  const skinClass = `skin_${member.preferences.skin}${
    member.preferences.sleep ? '_sleep' : ''
  }`;
  const costumeClass = member.preferences.costume ? 'costume' : 'equipped';
  const petClass = member.items.currentPet
    ? `Pet-${member.items.currentPet}`
    : '';
  const getGearClass = (gearType: string) =>
    member.items.gear[costumeClass][gearType];

  return (
    <div className="avatar-container">
      <div className={`avatar background_${member.preferences.background}`}>
        <div className="character-sprites">
          {member.items.currentMount && (
            <span className={`Mount_Body_${member.items.currentMount}`} />
          )}
          {/* Buffs that cause visual changes to avatar: Snowman, Ghost, Flower, etc */}
          {Object.entries(visualBuffs).map(([klass, item]) => (
            <Fragment key={item}>
              {member.stats.buffs[item] && (
                <span key={item} className={klass} />
              )}
            </Fragment>
          ))}
          {/* Show flower ALL THE TIME!!! */}
          {/* See https://github.com/HabitRPG/habitica/issues/7133 */}
          <span className={`hair_flower_${member.preferences.hair.flower}`} />
          <span className={`chair_${member.preferences.chair}`} />
          <span className={getGearClass('back')} />
          <span className={skinClass} />
          <span
            className={`${member.preferences.size}_shirt_${member.preferences.shirt}`}
          />
          <span className="head_0" />
          <span
            className={`${member.preferences.size}_${getGearClass('armor')}`}
          />
          <span className={`${getGearClass('back_collar')}`} />
          {['bangs', 'base', 'mustache', 'beard'].map((type) => (
            <span
              key={type}
              className={`hair_${type}_${member.preferences.hair[type]}_${member.preferences.hair.color}`}
            />
          ))}
          <span className={getGearClass('body')} />
          <span className={getGearClass('eyewear')} />
          <span className={getGearClass('head')} />
          <span className={getGearClass('headAccessory')} />
          <span className={`hair_flower_${member.preferences.hair.flower}`} />
          <span className={getGearClass('shield')} />
          <span className={getGearClass('weapon')} />
          {/* Resting */}
          {member.preferences.sleep && <span className="zzz" />}
          {/* Mount Head */}
          {member.items.currentMount && (
            <span className={`Mount_Head_${member.items.currentMount}`} />
          )}
          {/* Pet */}
          <span className={`current-pet ${petClass}`} />
        </div>
      </div>
    </div>
  );
}
