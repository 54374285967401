import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

import { DATE_FORMAT, DOW_FORMAT, TZ } from '../../lib/toDate';
import { useContext } from 'react';
import { DataContext } from '../../contexts';

function isSameWeek(day: dayjs.Dayjs, now: dayjs.Dayjs): boolean {
  return ((now.day() + 6) % 7) + 1 >= ((day.day() + 6) % 7) + 1;
}

export function Nights() {
  const now = dayjs().tz(TZ);

  const today = now.format(DATE_FORMAT);
  const data = useContext(DataContext);
  return (
    <div className="nights">
      {[0, 1, 2, 3, 4, 5, 6].map((i) => {
        const day = now.clone().subtract(i, 'days');
        const dow = day.format(DOW_FORMAT);
        const date = day.format(DATE_FORMAT);
        const nightName = data.nights.logs[date];
        const nightPic =
          data.nights.names[nightName] ||
          data.nights.names[date === today ? 'TODAY' : 'EMPTY'];

        return (
          <div
            className={`night ${!isSameWeek(day, now) ? ' previous-week' : ''}`}
            key={i}
          >
            <div className="day-of-the-week">{dow}</div>
            <img className="pic" alt={nightName} src={nightPic} />
          </div>
        );
      })}
    </div>
  );
}
