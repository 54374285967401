{
  "evilsanta": "Trapper Santa",
  "evilsanta2": "Find the Cub",
  "gryphon": "Fiery Gryphon",
  "hedgehog": "Hedgebeast",
  "ghoststag": "Ghost Stag",
  "rat": "Rat King",
  "octopus": "Octothulu",
  "harpy": "Harpy",
  "rooster": "Rooster",
  "spider": "Spider",
  "vice1": "Vice's Shade",
  "vice2": "Vice, Part 2: Find the Lair of the Wyrm",
  "vice3": "Vice, the Shadow Wyrm",
  "moonstone1": "Recidivate, Part 1: The Moonstone Chain",
  "moonstone2": "The Necromancer",
  "moonstone3": "Necro-Vice",
  "goldenknight1": "The Golden Knight, Part 1: A Stern Talking-To",
  "goldenknight2": "Gold Knight",
  "goldenknight3": "The Iron Knight",
  "basilist": "The Basi-List",
  "egghunt": "Egg Hunt",
  "dilatory": "The Dread Drag'on of Dilatory",
  "seahorse": "Sea Stallion",
  "atom1": "Attack of the Mundane, Part 1: Dish Disaster!",
  "atom2": "The SnackLess Monster",
  "atom3": "The Laundromancer",
  "owl": "The Night-Owl",
  "penguin": "Frost Penguin",
  "stressbeast": "The Abominable Stressbeast",
  "trex": "Flesh Tyrannosaur",
  "trexundead": "Skeletal Tyrannosaur",
  "rock": "Crystal Colossus",
  "bunny": "Killer Bunny",
  "slime": "Jelly Regent",
  "sheep": "Thunder Ram",
  "kraken": "The Kraken of Inkomplete",
  "whale": "Wailing Whale",
  "dilatorydistress1": "Dilatory Distress, Part 1: Message in a Bottle",
  "dilatorydistress2": "Water Skull Swarm",
  "dilatorydistress3": "Adva, the Usurping Mermaid",
  "cheetah": "Cheetah",
  "horse": "Night-Mare",
  "burnout": "Burnout",
  "frog": "Clutter Frog",
  "snake": "Serpent of Distraction",
  "unicorn": "The Unicorn Queen",
  "sabretooth": "Zombie Sabre Cat",
  "monkey": "Monstrous Mandrill",
  "snail": "Snail of Drudgery Sludge",
  "bewilder": "The Be-Wilder",
  "falcon": "Birds of Preycrastination",
  "treeling": "Tangle Tree",
  "axolotl": "Magical Axolotl",
  "turtle": "Task Flotsam",
  "armadillo": "Indulgent Armadillo",
  "cow": "Mootant Cow",
  "beetle": "CRITICAL BUG",
  "taskwoodsterror1": "Fire Skull Swarm",
  "taskwoodsterror2": "Terror in the Taskwoods, Part 2: Finding the Flourishing Fairies",
  "taskwoodsterror3": "Jacko of the Lantern",
  "ferret": "Nefarious Ferret",
  "dustbunnies": "Feral Dust Bunnies",
  "moon1": "Lunar Battle, Part 1: Find the Mysterious Shards",
  "moon2": "Overshadowing Stress",
  "moon3": "Monstrous Moon",
  "sloth": "Somnolent Sloth",
  "triceratops": "Trampling Triceratops",
  "stoikalmcalamity1": "Earth Skull Swarm",
  "stoikalmcalamity2": "Stoïkalm Calamity, Part 2: Seek the Icicle Caverns",
  "stoikalmcalamity3": "Icicle Drake Queen",
  "guineapig": "Guinea Pig Gang",
  "peacock": "Push-and-Pull Peacock",
  "butterfly": "Flaming Butterfry",
  "mayhemmistiflying1": "Air Skull Swarm",
  "mayhemmistiflying2": "Mayhem in Mistiflying, Part 2: In Which the Wind Worsens",
  "mayhemmistiflying3": "The Wind-Worker",
  "featheredfriendstext": "Feathered Friends Quest Bundle",
  "nudibranch": "NowDo Nudibranch",
  "splashypalstext": "Splashy Pals Quest Bundle",
  "hippo": "The Hippo-Crite",
  "farmfriendstext": "Farm Friends Quest Bundle",
  "witchyfamiliarstext": "Witchy Familiars Quest Bundle",
  "lostmasterclasser1": "The Mystery of the Masterclassers, Part 1: Read Between the Lines",
  "lostmasterclasser2": "The a'Voidant",
  "lostmasterclasser3": "Void Skull Swarm",
  "lostmasterclasser4": "Anti'zinnya",
  "yarn": "The Dread Yarnghetti",
  "winterqueststext": "Winter Quest Bundle",
  "pterodactyl": "Pterror-dactyl",
  "badger": "The Badgering Bother",
  "dysheartener": "The Dysheartener",
  "hugabugtext": "Hug a Bug Quest Bundle",
  "squirrel": "Sneaky Squirrel",
  "cuddlebuddiestext": "Cuddle Buddies Quest Bundle",
  "aquaticamigostext": "Aquatic Amigos Quest Bundle",
  "seaserpent": "The Mighty Sea Serpent",
  "kangaroo": "Catastrophic Kangaroo",
  "forestfriendstext": "Forest Friends Quest Bundle",
  "alligator": "Insta-Gator",
  "oddballstext": "Oddballs Quest Bundle",
  "birdbuddiestext": "Bird Buddies Quest Bundle",
  "velociraptor": "Veloci-Rapper",
  "mythicalmarvelstext": "Mythical Marvels Quest Bundle",
  "bronze": "Brazen Beetle",
  "dolphin": "Dolphin of Doubt",
  "silver": "The Silver Solution",
  "robot": "Mysterious Mechanical Marvels!",
  "rockingreptilestext": "Rocking Reptiles Quest Bundle",
  "delightfuldinostext": "Delightful Dinos Quest Bundle",
  "amber": "Trerezin",
  "ruby": "Ruby Rapport",
  "waffle": "Awful Waffle",
  "junglebuddiestext": "Jungle Buddies Quest Bundle",
  "fluorite": "Fluorite Elemental",
  "windup": "Clankton",
  "turquoise": "Turquoise Treasure Toil",
  "sandysidekickstext": "Sandy Sidekicks Quest Bundle",
  "blackpearl": "Asteroidea",
  "stone": "A Maze of Moss",
  "solarsystem": "Diversionoids",
  "onyx": "The Onyx Odyssey",
  "virtualpet": "Wotchimon",
  "pinkmarble": "Cupido"
}
