import './TaskName.scss';

import { useContext } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkEmoji from 'remark-emoji';

import { DataContext } from '../../contexts';

export function TaskName({ name }: { name: string }) {
  const data = useContext(DataContext);

  return (
    <div className="task-name" title={name}>
      <ReactMarkdown
        remarkPlugins={[remarkEmoji]}
        children={data.magic.TASK_NAME_MAPPING[name] || name}
      />
    </div>
  );
}
