{
  "basilist": {
    "boss": {
      "hp": 100,
      "str": 0.5
    }
  },
  "dustbunnies": {
    "boss": {
      "hp": 100,
      "str": 0.5
    }
  },
  "dilatoryDistress1": {
    "collect": {
      "fireCoral": {
        "count": 20
      },
      "blueFins": {
        "count": 20
      }
    }
  },
  "dilatoryDistress2": {
    "boss": {
      "hp": 500,
      "rage": {
        "healing": 0.3
      }
    }
  },
  "dilatoryDistress3": {
    "boss": {
      "hp": 1000,
      "str": 2
    }
  },
  "mayhemMistiflying1": {
    "boss": {
      "hp": 500,
      "rage": {
        "healing": 0.3
      }
    }
  },
  "mayhemMistiflying2": {
    "collect": {
      "mistifly1": {
        "count": 25
      },
      "mistifly2": {
        "count": 15
      },
      "mistifly3": {
        "count": 10
      }
    }
  },
  "mayhemMistiflying3": {
    "boss": {
      "hp": 1000,
      "str": 2
    }
  },
  "stoikalmCalamity1": {
    "boss": {
      "hp": 500,
      "rage": {
        "healing": 0.3
      }
    }
  },
  "stoikalmCalamity2": {
    "collect": {
      "icicleCoin": {
        "count": 40
      }
    }
  },
  "stoikalmCalamity3": {
    "boss": {
      "hp": 1000,
      "str": 2
    }
  },
  "taskwoodsTerror1": {
    "boss": {
      "hp": 500,
      "rage": {
        "healing": 0.3
      }
    }
  },
  "taskwoodsTerror2": {
    "collect": {
      "pixie": {
        "count": 25
      },
      "brownie": {
        "count": 15
      },
      "dryad": {
        "count": 10
      }
    }
  },
  "taskwoodsTerror3": {
    "boss": {
      "hp": 1000,
      "str": 2
    }
  },
  "lostMasterclasser1": {
    "collect": {
      "ancientTome": {
        "count": 40
      },
      "forbiddenTome": {
        "count": 40
      },
      "hiddenTome": {
        "count": 40
      }
    }
  },
  "lostMasterclasser2": {
    "boss": {
      "hp": 1500,
      "str": 2.5
    }
  },
  "lostMasterclasser3": {
    "boss": {
      "hp": 2000,
      "str": 3,
      "rage": {
        "healing": 0.3
      }
    }
  },
  "lostMasterclasser4": {
    "boss": {
      "hp": 3000,
      "str": 4,
      "rage": {
        "mpDrain": true
      }
    }
  },
  "alligator": {
    "boss": {
      "hp": 1100,
      "str": 2.5
    }
  },
  "armadillo": {
    "boss": {
      "hp": 600,
      "str": 1.5
    }
  },
  "axolotl": {
    "boss": {
      "hp": 500,
      "str": 1.5,
      "rage": {
        "healing": 0.3
      }
    }
  },
  "badger": {
    "boss": {
      "hp": 600,
      "str": 1.5
    }
  },
  "beetle": {
    "boss": {
      "hp": 500,
      "str": 1.5
    }
  },
  "bunny": {
    "boss": {
      "hp": 300,
      "str": 1.5
    }
  },
  "butterfly": {
    "boss": {
      "hp": 400,
      "str": 1.5
    }
  },
  "cheetah": {
    "boss": {
      "hp": 600,
      "str": 1.5
    }
  },
  "cow": {
    "boss": {
      "hp": 400,
      "str": 1.5
    }
  },
  "dilatory_derby": {
    "boss": {
      "hp": 300,
      "str": 1.5
    }
  },
  "dolphin": {
    "boss": {
      "hp": 300,
      "str": 1.25
    }
  },
  "falcon": {
    "boss": {
      "hp": 700,
      "str": 2
    }
  },
  "ferret": {
    "boss": {
      "hp": 400,
      "str": 1.5
    }
  },
  "frog": {
    "boss": {
      "hp": 300,
      "str": 1.5
    }
  },
  "ghost_stag": {
    "boss": {
      "hp": 1200,
      "str": 2.5
    }
  },
  "gryphon": {
    "boss": {
      "hp": 300,
      "str": 1.5
    }
  },
  "guineapig": {
    "boss": {
      "hp": 400,
      "str": 1.5
    }
  },
  "harpy": {
    "boss": {
      "hp": 600,
      "str": 1.5
    }
  },
  "hedgehog": {
    "boss": {
      "hp": 400,
      "str": 1.25
    }
  },
  "hippo": {
    "boss": {
      "hp": 800,
      "str": 2
    }
  },
  "horse": {
    "boss": {
      "hp": 500,
      "str": 1.5
    }
  },
  "kangaroo": {
    "boss": {
      "hp": 700,
      "str": 2
    }
  },
  "kraken": {
    "boss": {
      "hp": 800,
      "str": 2
    }
  },
  "monkey": {
    "boss": {
      "hp": 400,
      "str": 1.5
    }
  },
  "nudibranch": {
    "boss": {
      "hp": 400,
      "str": 1.5
    }
  },
  "octopus": {
    "boss": {
      "hp": 1200,
      "str": 2.5
    }
  },
  "owl": {
    "boss": {
      "hp": 500,
      "str": 1.5
    }
  },
  "peacock": {
    "boss": {
      "hp": 300,
      "str": 1.5
    }
  },
  "penguin": {
    "boss": {
      "hp": 400,
      "str": 1.5
    }
  },
  "pterodactyl": {
    "boss": {
      "hp": 1000,
      "str": 2
    }
  },
  "rat": {
    "boss": {
      "hp": 1200,
      "str": 2.5
    }
  },
  "rock": {
    "boss": {
      "hp": 400,
      "str": 1.5
    }
  },
  "rooster": {
    "boss": {
      "hp": 300,
      "str": 1.5
    }
  },
  "sabretooth": {
    "boss": {
      "hp": 1000,
      "str": 2
    }
  },
  "seaserpent": {
    "boss": {
      "hp": 1200,
      "str": 2.5
    }
  },
  "sheep": {
    "boss": {
      "hp": 300,
      "str": 1.5
    }
  },
  "slime": {
    "boss": {
      "hp": 400,
      "str": 1.5
    }
  },
  "sloth": {
    "boss": {
      "hp": 400,
      "str": 1.5
    }
  },
  "snail": {
    "boss": {
      "hp": 500,
      "str": 1.5
    }
  },
  "snake": {
    "boss": {
      "hp": 1100,
      "str": 2.5
    }
  },
  "spider": {
    "boss": {
      "hp": 400,
      "str": 1.5
    }
  },
  "squirrel": {
    "boss": {
      "hp": 700,
      "str": 2
    }
  },
  "treeling": {
    "boss": {
      "hp": 600,
      "str": 1.5
    }
  },
  "trex": {
    "boss": {
      "hp": 800,
      "str": 2
    }
  },
  "trex_undead": {
    "boss": {
      "hp": 500,
      "str": 2,
      "rage": {
        "healing": 0.3
      }
    }
  },
  "triceratops": {
    "boss": {
      "hp": 1200,
      "str": 2.5
    }
  },
  "turtle": {
    "boss": {
      "hp": 300,
      "str": 1.5
    }
  },
  "unicorn": {
    "boss": {
      "hp": 600,
      "str": 1.5
    }
  },
  "velociraptor": {
    "boss": {
      "hp": 900,
      "str": 2
    }
  },
  "whale": {
    "boss": {
      "hp": 500,
      "str": 1.5
    }
  },
  "yarn": {
    "boss": {
      "hp": 500,
      "str": 1.5
    }
  },
  "amber": {
    "boss": {
      "hp": 300,
      "str": 1.25
    }
  },
  "blackPearl": {
    "boss": {
      "hp": 725,
      "str": 1.75
    }
  },
  "bronze": {
    "boss": {
      "hp": 800,
      "str": 2
    }
  },
  "fluorite": {
    "boss": {
      "hp": 1200,
      "str": 2
    }
  },
  "onyx": {
    "collect": {
      "onyxStone": {
        "count": 25
      },
      "plutoRune": {
        "count": 10
      },
      "leoRune": {
        "count": 10
      }
    }
  },
  "pinkMarble": {
    "boss": {
      "hp": 1200,
      "str": 2,
      "rage": {
        "progressDrain": 0.5
      }
    }
  },
  "ruby": {
    "collect": {
      "rubyGem": {
        "count": 25
      },
      "venusRune": {
        "count": 10
      },
      "aquariusRune": {
        "count": 10
      }
    }
  },
  "silver": {
    "collect": {
      "silverIngot": {
        "count": 20
      },
      "moonRune": {
        "count": 15
      },
      "cancerRune": {
        "count": 15
      }
    }
  },
  "stone": {
    "collect": {
      "mossyStone": {
        "count": 25
      },
      "marsRune": {
        "count": 10
      },
      "capricornRune": {
        "count": 10
      }
    }
  },
  "turquoise": {
    "collect": {
      "turquoiseGem": {
        "count": 25
      },
      "sagittariusRune": {
        "count": 10
      },
      "neptuneRune": {
        "count": 10
      }
    }
  },
  "evilsanta": {
    "boss": {
      "hp": 300,
      "str": 1
    }
  },
  "evilsanta2": {
    "collect": {
      "tracks": {
        "count": 20
      },
      "branches": {
        "count": 10
      }
    }
  },
  "egg": {
    "collect": {
      "plainEgg": {
        "count": 40
      }
    }
  },
  "waffle": {
    "boss": {
      "hp": 500,
      "str": 2,
      "rage": {
        "progressDrain": 0.5
      }
    }
  },
  "virtualpet": {
    "boss": {
      "hp": 500,
      "str": 2,
      "rage": {
        "progressDrain": 0.5
      }
    }
  },
  "atom1": {
    "prerequisite": {},
    "collect": {
      "soapBars": {
        "count": 20
      }
    }
  },
  "atom2": {
    "boss": {
      "hp": 300,
      "str": 1
    }
  },
  "atom3": {
    "boss": {
      "hp": 800,
      "str": 1.5
    }
  },
  "goldenknight1": {
    "collect": {
      "testimony": {
        "count": 60
      }
    }
  },
  "goldenknight2": {
    "boss": {
      "hp": 1000,
      "str": 3
    }
  },
  "goldenknight3": {
    "boss": {
      "hp": 1700,
      "str": 3.5
    }
  },
  "moon1": {
    "collect": {
      "shard": {
        "count": 20
      }
    }
  },
  "moon2": {
    "boss": {
      "hp": 100,
      "str": 1.5
    }
  },
  "moon3": {
    "boss": {
      "hp": 1000,
      "str": 2
    }
  },
  "moonstone1": {
    "collect": {
      "moonstone": {
        "count": 100
      }
    }
  },
  "moonstone2": {
    "boss": {
      "hp": 1500,
      "str": 3
    }
  },
  "moonstone3": {
    "boss": {
      "hp": 2000,
      "str": 3.5
    }
  },
  "vice1": {
    "boss": {
      "hp": 750,
      "str": 1.5
    }
  },
  "vice2": {
    "collect": {
      "lightCrystal": {
        "count": 30
      }
    }
  },
  "vice3": {
    "boss": {
      "hp": 1500,
      "str": 3
    }
  },
  "robot": {
    "collect": {
      "bolt": {
        "count": 15
      },
      "gear": {
        "count": 10
      },
      "spring": {
        "count": 10
      }
    }
  },
  "solarSystem": {
    "boss": {
      "hp": 1500,
      "str": 2.5
    }
  },
  "windup": {
    "boss": {
      "hp": 1000,
      "str": 1
    }
  },
  "bewilder": {
    "boss": {
      "hp": 20000000,
      "str": 1,
      "def": 1,
      "rage": {}
    }
  },
  "burnout": {
    "boss": {
      "hp": 11000000,
      "str": 2.5,
      "def": 1,
      "rage": {}
    }
  },
  "dilatory": {
    "boss": {
      "hp": 5000000,
      "str": 1,
      "def": 1,
      "rage": {}
    }
  },
  "dysheartener": {
    "boss": {
      "hp": 17000000,
      "str": 1.25,
      "def": 1,
      "rage": {}
    },
    "colors": {
      "dark": "#410F2A",
      "medium": "#5C1130",
      "light": "#931F4D",
      "extralight": "#DC4069"
    }
  },
  "stressbeast": {
    "boss": {
      "hp": 2750000,
      "str": 1,
      "def": 1,
      "rage": {
        "healing": 0.3
      },
      "desperation": {
        "threshold": 500000,
        "str": 3.5,
        "def": 2
      }
    }
  }
}
