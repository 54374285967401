import './dialog.scss';

import dayjs from 'dayjs';

import { useState } from 'react';

import { dailyStatusFromHistory, groupedDailyHistories } from '../../lib/daily';
import { Task } from '../../lib/datatype';
import { DATE_FORMAT, DAYS_TO_SHOW } from '../../lib/toDate';
import {
  completedChar,
  TaskMarkDialog,
  uncompletedChar,
} from './TaskMarkDialog';
import { TaskName } from './TaskName';
import { TasksTableHeader } from './TasksTableHeader';

function DailyStats({ daily, now }: { daily: Task; now: dayjs.Dayjs }) {
  const historyByDate = groupedDailyHistories(daily);
  const today = now.format(DATE_FORMAT);

  return (
    <>
      {Array(DAYS_TO_SHOW)
        .fill(null)
        .map((_, index) => {
          const date = now
            .clone()
            .subtract(DAYS_TO_SHOW - 1 - index, 'days')
            .format(DATE_FORMAT);
          const histories = historyByDate.get(date) || [];
          const { completed, isDue, isEmpty, isSkip } = dailyStatusFromHistory(
            date,
            daily,
            histories
          );

          return (
            <td
              className={`day${isEmpty ? ' empty' : ''}${
                isSkip ? ' skip' : ''
              }`}
              key={index}
            >
              {completed && <div className="completed">{completedChar}</div>}
              {!completed && isDue && (
                <>
                  {date === today && <div className="today">?</div>}
                  {date !== today && (
                    <div className="is-due">{uncompletedChar}</div>
                  )}
                </>
              )}
            </td>
          );
        })}
    </>
  );
}

export function DailiesTable({
  userIndex,
  tasks,
}: {
  userIndex: number;
  tasks: Task[];
}) {
  const dailies = tasks.filter(
    (t) => t.type === 'daily' && !t.challenge?.broken
  );
  const now = dayjs();

  const [isOpen, setIsOpen] = useState(false);
  const [selectedTask, setSelectedTask] = useState<Task | null>(null);

  return (
    <>
      <table className="dailies tasks-table">
        <TasksTableHeader section={'Dailies'} now={now} />
        <tbody>
          {dailies.map((daily, index) => (
            <tr className="task" key={index}>
              <DailyStats daily={daily} now={now} />
              <td>
                <div
                  onClick={() => {
                    setSelectedTask(daily);
                    setIsOpen(true);
                  }}
                >
                  <TaskName name={daily.text} />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <TaskMarkDialog
        userIndex={userIndex}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        selectedTask={selectedTask}
      />
    </>
  );
}
