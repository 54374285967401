import * as Dialog from '@radix-ui/react-dialog';
import { Cross2Icon } from '@radix-ui/react-icons';
import dayjs from 'dayjs';
import { useLocalStorage } from 'usehooks-ts';

import { dailyStatusFromHistory, groupedDailyHistories } from '../../lib/daily';
import { Task } from '../../lib/datatype';
import { DATE_FORMAT } from '../../lib/toDate';
import { TaskName } from './TaskName';

export const completedChar = '✓';
export const uncompletedChar = '𐄂';

export function TaskMarkDialog({
  userIndex,
  isOpen,
  setIsOpen,
  selectedTask,
}: {
  userIndex: number;
  isOpen: boolean;
  setIsOpen: (_: boolean) => void;
  selectedTask: Task | null;
}) {
  const [unlockUntil] = useLocalStorage<number>('unlockUntil', 0);

  function toggleTaskCompleted(dir: string | undefined = undefined) {
    const url = `https://nr.tdfamily.app/habitica/${userIndex}/mark/${
      selectedTask?._id
    }/${dir || (selectedTask?.completed ? 'down' : 'up')}/${(
      window.location.hash || ''
    ).substring(1)}`;

    const now = new Date().getTime();
    if (now < unlockUntil) {
      fetch(url, {
        method: 'POST',
      });
    }

    setIsOpen(false);
  }

  function getIsDailyCompletedToday(task?: Task): boolean {
    if (!task || task.type !== 'daily') {
      return false;
    }
    const today = dayjs().format(DATE_FORMAT);
    const histories = groupedDailyHistories(task).get(today) || [];
    const { completed } = dailyStatusFromHistory(today, task, histories);
    return completed;
  }
  const isDailyCompletedToday = getIsDailyCompletedToday(selectedTask);

  return (
    <Dialog.Root open={isOpen} onOpenChange={setIsOpen}>
      <Dialog.Portal container={document.querySelector<HTMLElement>('#app')}>
        <Dialog.Overlay className="DialogOverlay" />
        <Dialog.Content className="DialogContent">
          <Dialog.Title className="DialogTitle">
            {selectedTask && selectedTask.type === 'daily' && (
              <>
                Mark Daily as{' '}
                {isDailyCompletedToday ? uncompletedChar : completedChar}{' '}
              </>
            )}
            {selectedTask && selectedTask.type === 'habit' && <>Score Habit</>}
          </Dialog.Title>
          <Dialog.Description className="DialogDescription">
            {selectedTask && <TaskName name={selectedTask.text} />}
          </Dialog.Description>
          <div
            style={{
              marginTop: 25,
              display: 'flex',
            }}
          >
            {selectedTask && selectedTask.type === 'daily' && (
              <>
                {!isDailyCompletedToday && <div style={{ flex: 1 }} />}
                <button
                  className="Button"
                  onClick={() => toggleTaskCompleted()}
                >
                  {isDailyCompletedToday ? uncompletedChar : completedChar}
                </button>
              </>
            )}
            {selectedTask && selectedTask.type === 'habit' && (
              <>
                {(selectedTask.up && (
                  <button
                    className="Button up"
                    onClick={() => toggleTaskCompleted('up')}
                  >
                    +
                  </button>
                )) || <div style={{ flex: 1 }} />}
                <div style={{ flex: 1 }} />
                {selectedTask.down && (
                  <button
                    className="Button down"
                    onClick={() => toggleTaskCompleted('down')}
                  >
                    -
                  </button>
                )}
              </>
            )}
          </div>
          <Dialog.Close asChild>
            <button className="IconButton" aria-label="Close">
              <Cross2Icon />
            </button>
          </Dialog.Close>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}
