import { useContext } from 'react';

import { DataContext } from '../../contexts';
import { StatsBar } from '../../habitica/StatsBar';
import questContent from '../../habitica/external/questsContent.json';
import quest from '../../habitica/external/quests.json';

export function Quest() {
  const data = useContext(DataContext);
  if (!data.party.quest?.active) {
    return <></>;
  }

  const hp: number = data.party.quest.progress.hp || 0;
  const pendingAttack = data.users
    .map((user) => user.party.quest.progress.up || (0 as number))
    .reduce((a, b) => a + b, 0);
  const left = hp - Math.min(hp, pendingAttack);

  const questKey: string = data.party.quest.key;
  const bossNames = questContent as Record<string, string>;
  const questData = quest as Record<string, any>;

  return (
    <div className="quest">
      <div className={`boss quest_${questKey}`} />
      <div className="boss-name">{bossNames[questKey.toLowerCase()]}</div>
      {!!hp && (
        <div className="boss-health">
          <StatsBar label="health" value={left} maxValue={hp} toFixed={2} />
        </div>
      )}
      {Object.keys(data.party.quest.progress.collect || {}).map((key) => (
        <div className="quest-collect" key={key}>
          <div className={`quest-item-icon quest_${questKey}_${key}`} />
          <StatsBar
            label="quest-item"
            value={data.party.quest.progress.collect[key]}
            maxValue={questData[questKey]?.collect?.[key]?.count || 10}
            toFixed={0}
          />
        </div>
      ))}
    </div>
  );
}
