import dayjs from 'dayjs';
import { useState } from 'react';

import { HabitHistory, Task } from '../../lib/datatype';
import { groupByToMap } from '../../lib/groupByToMap';
import { TaskMarkDialog } from './TaskMarkDialog';
import { TaskName } from './TaskName';
import { TasksTableHeader } from './TasksTableHeader';
import { toDate, DAYS_TO_SHOW, DATE_FORMAT } from '../../lib/toDate';

function HabitStats({ habit, now }: { habit: Task; now: dayjs.Dayjs }) {
  const historyByDate = groupByToMap<HabitHistory, string>(
    habit.history,
    (h: HabitHistory) => toDate(h.date)
  );

  return (
    <>
      {Array(DAYS_TO_SHOW)
        .fill(null)
        .map((_, index) => {
          const date = now
            .clone()
            .subtract(DAYS_TO_SHOW - 1 - index, 'days')
            .format(DATE_FORMAT);
          const up =
            historyByDate.get(date)?.reduce((acc, h) => h.scoredUp + acc, 0) ||
            0;
          const down =
            historyByDate
              .get(date)
              ?.reduce((acc, h) => h.scoredDown + acc, 0) || 0;
          const isEmpty = up === 0 && down === 0;

          return (
            <td className={`day${isEmpty ? ' empty' : ''}`} key={index}>
              {up > 0 && <div className="up">+{up}</div>}
              {down > 0 && <div className="down">-{down}</div>}
            </td>
          );
        })}
    </>
  );
}

export function HabitsTable({
  userIndex,
  tasks,
}: {
  userIndex: number;
  tasks: Task[];
}) {
  const habits = tasks.filter(
    (t) => t.type === 'habit' && !t.challenge?.broken
  );
  const now = dayjs();

  const [isOpen, setIsOpen] = useState(false);
  const [selectedTask, setSelectedTask] = useState<Task | null>(null);

  return (
    <>
      <table className="habits tasks-table">
        <TasksTableHeader section={'Habits'} now={now} />
        <tbody>
          {habits.map((habit, index) => (
            <tr className="task" key={index}>
              <HabitStats habit={habit} now={now} />
              <td>
                <div
                  onClick={() => {
                    setSelectedTask(habit);
                    setIsOpen(true);
                  }}
                >
                  <TaskName name={habit.text} />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <TaskMarkDialog
        userIndex={userIndex}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        selectedTask={selectedTask}
      />
    </>
  );
}
