import dayjs from 'dayjs';

import { DailyHistory, Task } from './datatype';
import { groupByToMap } from './groupByToMap';
import { toDate } from './toDate';

export function dailyStatusFromHistory(
  date: string, // DATE_FORMAT
  daily: Task,
  histories: DailyHistory[]
): {
  completed: boolean;
  isDue: boolean;
  isSkip: boolean;
  isEmpty: boolean;
} {
  function calcIsDue(): boolean {
    if (daily.frequency === 'daily') {
      if (daily.everyX === 1) {
        return true;
      }
      // TODO:
    }
    if (daily.frequency === 'weekly') {
      if (daily.everyX === 1) {
        switch (dayjs(date).day()) {
          case 0:
            return daily.repeat.su;
          case 1:
            return daily.repeat.m;
          case 2:
            return daily.repeat.t;
          case 3:
            return daily.repeat.w;
          case 4:
            return daily.repeat.th;
          case 5:
            return daily.repeat.f;
          case 6:
            return daily.repeat.s;
        }
      }
      // TODO:
    }
    // fallback
    return histories.every((d) => d.isDue) && histories.length > 0;
  }
  // NOTE: the daily history might have more than one entries per day.
  // Seems the cronjob created a {completed: false, isDue: true} in the early morning by default.
  const completed = histories.some((d) => d.completed);
  const isDue = calcIsDue();
  const isSkip = !isDue;
  const isEmpty = !completed && !isDue;

  return {
    completed,
    isDue,
    isSkip,
    isEmpty,
  };
}

export function groupedDailyHistories(
  daily: Task
): Map<string, DailyHistory[]> {
  return groupByToMap<DailyHistory, string>(
    daily.history.concat([
      {
        date: new Date(daily.updatedAt).getTime(),
        completed: daily.completed,
        isDue: daily.isDue,
        value: daily.value,
      } as DailyHistory,
    ]),
    (d: DailyHistory) => toDate(d.date)
  );
}
