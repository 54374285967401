import './BankPage.scss';

import dayjs from 'dayjs';
import { useContext } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useSwipeable } from 'react-swipeable';

import { DataContext } from '../../contexts';
import UserCard from '../../habitica/UserCard';
import { isPerfectDay } from '../../lib/perfectDay';
import { DATE_FORMAT } from '../../lib/toDate';

export function BankPage() {
  const data = useContext(DataContext);

  const today = dayjs().format(DATE_FORMAT);
  const { index } = useParams();
  const userIndex = parseInt(index || '', 10);
  const perfectToday = isPerfectDay(
    today,
    data.tasks[userIndex],
    data.magic.specialHabit
  );

  const navigate = useNavigate();
  const usersNum = data.users.length;
  const userSwitchHandler = useSwipeable({
    onSwipedLeft: () => {
      navigate('/bank/' + ((userIndex + 1 + usersNum) % usersNum));
    },
    onSwipedRight: () => {
      navigate('/bank/' + ((userIndex - 1 + usersNum) % usersNum));
    },
    touchEventOptions: {
      passive: false,
    },
  });

  const bankLogs = data.bankLogs[userIndex];

  return (
    <div id="bank-page" {...userSwitchHandler}>
      <div className="header">
        <Link className="back" to="/">
          🔙
        </Link>
        <UserCard
          user={data.users[userIndex]}
          index={userIndex}
          perfectToday={perfectToday}
        />
      </div>
      <table className="details">
        {bankLogs.map((bankLog) => (
          <tr>
            {bankLog.map((b) => (
              <td>{b}</td>
            ))}
          </tr>
        ))}
      </table>
    </div>
  );
}
