import './member-details.scss';

import { useNavigate } from 'react-router-dom';

import { User } from '../lib/datatype';
import { StatsBar } from './StatsBar';

interface MemberDetailsProps {
  member: User;
  perfectToday: boolean;
  balance: number | string;
  index: number;
}

// https://github.com/HabitRPG/habitica/blob/0fb1fd57568faa9b2bea0d11b72ddc2dc88f756f/website/client/src/components/memberDetailsNew.vue
export default function MemberDetails({
  member,
  perfectToday,
  balance,
  index,
}: MemberDetailsProps) {
  const navigate = useNavigate();
  return (
    <div className="member-details">
      <div className="basic">
        <span className={`name${perfectToday ? ' perfect' : ''}`}>
          {member.profile.name}
        </span>{' '}
        <span className="level">L{member.stats.lvl}</span>{' '}
        <span className={`icon class ${member.stats.class}`}></span>
      </div>
      <div
        onClick={(e) => {
          navigate('/bank/' + index.toString());
          e.stopPropagation();
        }}
      >
        {balance !== 0 && (
          <>
            <span className="icon balance" /> {balance}{' '}
          </>
        )}
        <span className="icon gold" /> {Math.floor(member.stats.gp * 10) / 10}{' '}
        {!!member.balance && (
          <>
            <span className="icon gem" /> {Math.floor(member.balance * 4)}{' '}
          </>
        )}
        {!!member.party.quest.progress.up && (
          <>
            <span className="icon sword" />{' '}
            {member.party.quest.progress.up.toFixed(2)}
          </>
        )}
      </div>
      <StatsBar
        label="health"
        value={member.stats.hp}
        maxValue={member.stats.maxHealth}
      />
      <StatsBar
        label="experience"
        value={member.stats.exp}
        maxValue={member.stats.toNextLevel}
      />
      <StatsBar
        label="mana"
        value={member.stats.mp}
        maxValue={member.stats.maxMP}
      />
    </div>
  );
}
