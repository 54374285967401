import './app.scss';

import { useEffect } from 'react';
import useFetch from 'react-fetch-hook';
import { Route, Routes, useLocation } from 'react-router-dom';
import { useSwipeable } from 'react-swipeable';
import { useLocalStorage } from 'usehooks-ts';

import { DataContext } from './contexts';
import { Data } from './lib/datatype';
import { BankPage } from './pages/bank/BankPage';
import { OverviewPage } from './pages/overview/OverviewPage';
import { UserPage } from './pages/user/UserPage';

export default function App() {
  const { isLoading, data, error } = useFetch<Data>(
    `https://nr.tdfamily.app/habitica/j/${(
      window.location.hash || ''
    ).substring(1)}`
  );

  const location = useLocation();
  const [, setMemoryPath] = useLocalStorage('memory-path', '/');
  useEffect(() => {
    setMemoryPath(location.pathname);
  }, [location]);

  const tapToClick = useSwipeable({
    onTap: (e) => {
      e.event.target?.dispatchEvent(
        new MouseEvent('click', {
          view: window,
          bubbles: e.event.bubbles,
          cancelable: e.event.cancelable,
        })
      );
      e.event.preventDefault();
    },
    touchEventOptions: {
      passive: false,
    },
  });

  return (
    <div id="app" {...tapToClick}>
      {isLoading && <>Loading</>}
      {!isLoading && error && (
        <>
          {error.status} {error.statusText}
        </>
      )}
      {!isLoading && data && (
        <DataContext.Provider value={data}>
          <Routes>
            <Route path="/" element={<OverviewPage />} />
            <Route path="bank/:index" element={<BankPage />} />
            <Route path="/:index" element={<UserPage />} />
          </Routes>
        </DataContext.Provider>
      )}
    </div>
  );
}
