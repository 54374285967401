import { useState } from 'react';
// @ts-expect-error: no declaration file
import ReactCountdownClock from 'react-countdown-clock';
import { DOWN, SwipeEventData, UP, useSwipeable } from 'react-swipeable';
import { useLocalStorage } from 'usehooks-ts';

export function Lock() {
  const [unlockUntil, setUnlockUntil] = useLocalStorage<number>(
    'unlockUntil',
    0
  );
  const [now, setNow] = useState<number>(new Date().getTime());
  const secondsLeft = (unlockUntil - now) / 1000;
  const unlocked = secondsLeft > 0;

  const unlockPattern = [UP, DOWN, UP, DOWN];
  const lockPattern = [DOWN, DOWN, DOWN];

  const [pIdx, setPIdx] = useState(0);

  const lockNow = () => {
    setUnlockUntil(0);
    setPIdx(0);
  };
  const unlockNow = () => {
    const t = new Date().getTime();
    setNow(t);
    setUnlockUntil(t + 5 * 60 * 1000);
    setTimeout(() => setPIdx(0), 50);
  };

  const handleSwiped = (eventData: SwipeEventData) => {
    const pattern = unlocked ? lockPattern : unlockPattern;
    const action = unlocked ? lockNow : unlockNow;

    if (eventData.dir === pattern[pIdx]) {
      // user successfully got to the end of the pattern!
      if (pIdx + 1 === pattern.length) {
        setPIdx(pattern.length);
        action();
      } else {
        // user is cont. with the pattern
        setPIdx((i) => (i += 1));
      }
    } else {
      // user got the next pattern step wrong, reset pattern
      setPIdx(0);
    }
  };

  const handlers = useSwipeable({
    onSwiped: handleSwiped,
    onTouchStartOrOnMouseDown: ({ event }) => event.preventDefault(),
    touchEventOptions: { passive: false },
    preventScrollOnSwipe: true,
    trackMouse: true,
  });

  return (
    <div className="lock" {...handlers}>
      <div className={`icon ${unlocked ? 'unlocked' : 'locked'}`} />
      {unlocked && (
        <div className="timer">
          <ReactCountdownClock
            size={100}
            seconds={secondsLeft}
            onComplete={lockNow}
          />
        </div>
      )}
    </div>
  );
}
