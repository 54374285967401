import { dailyStatusFromHistory, groupedDailyHistories } from './daily';
import { HabitHistory, Task } from './datatype';
import { isSpecialHabit } from './habit';
import { toDate } from './toDate';

export function isPerfectDay(
  date: string,
  tasks: Task[],
  specialHabit: string[]
): boolean {
  for (const task of tasks) {
    if (task.type === 'daily' && !task.challenge?.broken) {
      const historyByDate = groupedDailyHistories(task);
      const histories = historyByDate.get(date) || [];
      const { completed, isSkip } = dailyStatusFromHistory(
        date,
        task,
        histories
      );

      if (!completed && !isSkip) {
        return false;
      }
    }

    if (task.type === 'habit') {
      if (isSpecialHabit(task, specialHabit)) {
        const scoredUp = (task.history as HabitHistory[]).some(
          (h) => toDate(h.date) === date && h.scoredUp
        );
        if (!scoredUp) {
          return false;
        }
      }
    }
  }
  return true;
}
