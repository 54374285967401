import './OverviewPage.scss';

import dayjs from 'dayjs';
import emoji from 'node-emoji';
import { useContext } from 'react';

import { DataContext } from '../../contexts';
import UserCard from '../../habitica/UserCard';
import { dailyStatusFromHistory, groupedDailyHistories } from '../../lib/daily';
import { HabitHistory, Task } from '../../lib/datatype';
import { isSpecialHabit } from '../../lib/habit';
import { isPerfectDay } from '../../lib/perfectDay';
import { DATE_FORMAT, toDate } from '../../lib/toDate';
import { Lock } from './Lock';
import { Nights } from './Nights';
import { Quest } from './Quest';

type DisplayOnly = undefined | 'pending' | 'completed' | 'down';

interface IconProps {
  task: Task;
  today: string;
  displayOnly?: DisplayOnly;
}

function IconInteral({ task }: IconProps) {
  const data = useContext(DataContext);
  const taskName = data.magic.TASK_NAME_MAPPING[task.text] || task.text;
  const foundMarkDownImage = taskName.match(/^!\[.*\]\((.+)\)/);
  if (foundMarkDownImage) {
    const imgUrl = foundMarkDownImage[1];
    return <img className="task-icon img" src={imgUrl} title={task.text} />;
  }
  const foundEmoji = taskName.match(/^:(.+):/);
  if (foundEmoji) {
    return (
      <div className="task-icon emoji" title={task.text}>
        {emoji.get(foundEmoji[1])}
      </div>
    );
  }

  // Handles emoji
  // https://www.samanthaming.com/tidbits/83-4-ways-to-convert-string-to-character-array/
  const letter = (Array.from(taskName) as string[])[0].toUpperCase();
  return (
    <div
      className={`task-icon ${letter.length == 1 ? 'letter' : 'emoji'}`}
      title={task.text}
    >
      {letter}
    </div>
  );
}

function Icon(props: IconProps) {
  return (
    <div className="task-icon-container">
      <IconInteral {...props} />
      <div className={`badge ${props.displayOnly}`} />
    </div>
  );
}

function HabitTaskIcon(props: IconProps) {
  const data = useContext(DataContext);

  if (props.displayOnly === 'pending') {
    if (isSpecialHabit(props.task, data.magic.specialHabit)) {
      const scoredUp = (props.task.history as HabitHistory[]).some(
        (h) => toDate(h.date) === props.today && h.scoredUp
      );
      if (!scoredUp) {
        return <Icon {...props} />;
      }
    }
    return null;
  }

  if (
    (props.task.history as HabitHistory[]).some(
      (h) =>
        toDate(h.date) === props.today &&
        ((h.scoredUp && props.displayOnly === 'completed') ||
          (h.scoredDown && props.displayOnly === 'down'))
    )
  ) {
    return <Icon {...props} />;
  }
  return null;
}

function DailyTaskIcon(props: IconProps) {
  if (props.displayOnly === 'down') {
    return null;
  }

  const histories = groupedDailyHistories(props.task).get(props.today) || [];
  const { completed, isSkip } = dailyStatusFromHistory(
    props.today,
    props.task,
    histories
  );

  if (props.displayOnly === 'pending') {
    if (!isSkip && !completed) {
      return <Icon {...props} />;
    }

    return null;
  }

  if (completed) {
    return <Icon {...props} />;
  }
  return null;
}

function TaskIcon(props: IconProps) {
  if (props.task.type === 'habit') {
    return <HabitTaskIcon {...props} />;
  }
  if (props.task.type === 'daily') {
    return <DailyTaskIcon {...props} />;
  }
  return null;
}

export function OverviewPage() {
  const data = useContext(DataContext);

  const today = dayjs().format(DATE_FORMAT);
  const perfectTodays = data.tasks.map((tasks) =>
    isPerfectDay(today, tasks, data.magic.specialHabit)
  );

  return (
    <div id="overview" className="card-grid-container">
      <Nights />
      <Quest />
      <Lock />
      {data.users.map((user, index) => (
        <div key={index} className={`user-panel user-${index}`}>
          <UserCard
            user={user}
            index={index}
            perfectToday={perfectTodays[index]}
          />
          <div className="tasks">
            {(data.tasks[index] as Task[]).map((task, taskIndex) => (
              <TaskIcon
                key={taskIndex}
                task={task}
                today={today}
                displayOnly="completed"
              />
            ))}
          </div>
          <div className="tasks">
            {(data.tasks[index] as Task[]).map((task, taskIndex) => (
              <TaskIcon
                key={taskIndex}
                task={task}
                today={today}
                displayOnly="down"
              />
            ))}
          </div>
          <div className="tasks pending">
            {(data.tasks[index] as Task[]).map((task, taskIndex) => (
              <TaskIcon
                key={taskIndex}
                task={task}
                today={today}
                displayOnly="pending"
              />
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}
