import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { MemoryRouter } from 'react-router-dom';
import { useLocalStorage } from 'usehooks-ts';

import App from './App';

function Index() {
  const [memoryPath] = useLocalStorage('memory-path', '/');
  return (
    <StrictMode>
      <MemoryRouter initialEntries={[memoryPath]}>
        <App />
      </MemoryRouter>
    </StrictMode>
  );
}

createRoot(document.getElementById('root')!).render(<Index />);
