import dayjs from 'dayjs';

import { DAYS_TO_SHOW, DOW_FORMAT } from '../../lib/toDate';

export function TasksTableHeader({
  section,
  now,
}: {
  section: string;
  now: dayjs.Dayjs;
}) {
  return (
    <thead>
      <tr>
        {Array(DAYS_TO_SHOW)
          .fill(null)
          .map((_, index) => {
            const date = now.clone().subtract(DAYS_TO_SHOW - 1 - index, 'days');
            return (
              <th key={index} className="dow">
                {date.format(DOW_FORMAT)}
              </th>
            );
          })}
        <th className="section">{section}</th>
      </tr>
    </thead>
  );
}
