import './UserCard.scss';

import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { DataContext } from '../contexts';
import { User } from '../lib/datatype';
import Avatar from './Avatar';
import MemberDetails from './MemberDetails';

interface UserCardProps {
  user: User;
  index: number;
  perfectToday: boolean;
}

export default function UserCard({ user, index, perfectToday }: UserCardProps) {
  const navigate = useNavigate();
  const data = useContext(DataContext);

  return (
    <div className="user-card" onClick={() => navigate('/' + index.toString())}>
      <Avatar member={user} />
      <MemberDetails
        index={index}
        member={user}
        perfectToday={perfectToday}
        balance={data.balance[index]}
      />
    </div>
  );
}
