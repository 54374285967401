interface StatsBarProps {
  label: string;
  value: number;
  maxValue: number;
  toFixed?: number;
}
export function StatsBar(props: StatsBarProps) {
  const toFixed = props.toFixed || 0;
  return (
    <div className="stats-bar">
      <div className="progress">
        <div
          className={`progress-bar bg-${props.label}`}
          style={{
            width: `${Math.min(100, (props.value * 100.0) / props.maxValue)}%`,
          }}
        ></div>
      </div>
      <span className="value">
        {props.value.toFixed(toFixed)} / {props.maxValue.toFixed(toFixed)}
      </span>
    </div>
  );
}
